import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, ActivationEnd, Router, RouterOutlet } from "@angular/router";
import { Subscription, interval, combineLatest, firstValueFrom, Observable } from "rxjs";
import { filter, take, map, startWith, share, shareReplay } from "rxjs/operators";
import { DatePipe, TitleCasePipe } from "@angular/common";
import { SingularPipe } from "src/app/pipes/singular.pipe";

import { Constants } from "../../../constants/constants";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";

import { ReportComponent } from "../report/report.component";
import { ReportsService } from "../reports.service";
import { Report } from "../report";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";

@Component({
    selector: "app-report-list",
    templateUrl: "./report-list.component.html",
    providers: [TitleCasePipe, SingularPipe, DatePipe]
})
export class ReportListComponent implements OnInit, OnDestroy {
    @ViewChild(RouterOutlet) report: RouterOutlet;
    refreshing = false;
    reports: Report[] = [];
    reportID: number;
    selectedRows: Array<Report> = [];

    urls = Constants.urls;

    private reportsRefreshSubscription: Subscription;
    tableColumnsSchema: TableSchema<Report>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 280,
            visible: true,
            sticky: 1,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: row => row.name,
            textValue: row => row.name
        },
        {
            header: this.translate.instant("TAG"),
            columnDef: "access_tag",
            width: 200,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => row.resourceTags.map(tag => tag.name).join(", "),
                row => row.resourceTags.map(tag => tag.name).join(", "),
                row => !!row.resourceTags.length
            ),
            sortBy: row => row.resourceTags.map(tag => tag.name).join(", "),
            textValue: row => row.resourceTags.map(tag => tag.name).join(", ")
        },
        {
            header: this.translate.instant("TYPE"),
            columnDef: "type",
            width: 200,
            visible: true,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => this.titleCasePipe.transform(row.type),
                row => this.titleCasePipe.transform(row.type),
                () => true
            ),
            sortBy: row => this.titleCasePipe.transform(row.type),
            textValue: row => this.titleCasePipe.transform(row.type)
        },
        {
            header: this.translate.instant("OBJECT_TYPES"),
            columnDef: "object_types",
            width: 200,
            visible: true,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => this.getObjectTypesColumnInput(row),
                row => this.getObjectTypesColumnInput(row),
                () => true
            ),
            sortBy: row => this.getObjectTypesColumnInput(row),
            textValue: row => this.getObjectTypesColumnInput(row)
        },
        {
            header: this.translate.instant("GRANULARITY"),
            columnDef: "granularity",
            width: 280,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => this.getGranularityColumnInput(row),
                row => this.getGranularityColumnInput(row),
                () => true
            ),
            sortBy: row => this.getGranularityColumnInput(row),
            textValue: row => this.getGranularityColumnInput(row)
        },
        {
            header: this.translate.instant("TIME_PERIOD"),
            columnDef: "time_period",
            width: 280,
            visible: true,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => this.getTimePeriodColumnInput(row),
                row => this.getTimePeriodColumnInput(row),
                () => true
            ),
            sortBy: row => this.getTimePeriodColumnInput(row),
            textValue: row => this.getTimePeriodColumnInput(row)
        }
    ];
    private resourceTags$ = this.sharedService.getResourceTagsByType("report_templates").pipe(take(1));
    private userPermission$ = this.userService.userPermissions.pipe(
        map(
            userPermissions =>
                userPermissions.is_zixi_support &&
                !userPermissions.is_zixi_admin &&
                !userPermissions.is_zixi_support_write
        ),
        take(1)
    );

    canAddReport$ = combineLatest([this.userPermission$, this.resourceTags$]).pipe(
        map(([userPermission, tags]) => !tags?.length || userPermission)
    );

    reports$ = this.reportsService.reports.pipe(
        filter(reports => !!reports),
        map(reports => [...reports])
    );

    selectedReportId$: Observable<number>;

    constructor(
        private router: Router,
        private reportsService: ReportsService,
        public sharedService: SharedService,
        private modalService: ModalService,
        private userService: UsersService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titleCasePipe: TitleCasePipe,
        private singularPipe: SingularPipe,
        private datePipe: DatePipe,
        private activatedRoute: ActivatedRoute
    ) {
        // Set Title
        this.titleService.setTitle("REPORTS", "");
    }

    ngOnInit() {
        this.selectedReportId$ = this.router.events.pipe(
            filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0),
            map((event: ActivationEnd) => Number(event.snapshot.params.id) || null),
            startWith(Number(this.activatedRoute.snapshot.firstChild?.params.id)),
            share(),
            shareReplay(1)
        );

        combineLatest([this.selectedReportId$, this.reports$]).subscribe(([reportId, reports]) => {
            if (reportId) {
                const r = reports.find(report => report.id === reportId);
                this.selectedRows = r ? [r] : [];
            } else {
                this.selectedRows = [];
            }
        });

        // Start Auto Refresh
        this.startReportsRefresh();
    }

    ngOnDestroy() {
        this.stopReportsRefresh();
    }

    private getObjectTypesColumnInput(report: Report): string {
        let input = "";
        if (report.feeders) input = this.translate.instant("FEEDERS") + ",";
        if (report.clusters) input = input + this.translate.instant("CLUSTERS") + ",";
        if (report.broadcasters) input = input + this.translate.instant("BROADCASTERS") + ",";
        if (report.receivers) input = input + this.translate.instant("RECEIVERS") + ",";
        if (report.sources) input = input + this.translate.instant("SOURCES") + ",";
        if (report.channels) input = input + this.translate.instant("CHANNELS") + ",";
        if (report.targets) input = input + this.translate.instant("TARGETS") + ",";
        if (report.options.transcoding) input = input + this.translate.instant("TRANSCODING") + ",";

        // removing the last comma
        return input.slice(0, -1);
    }

    private getGranularityColumnInput(report: Report): string {
        switch (report.breakdown) {
            case "1h":
                return this.translate.instant("HOUR");
            case "1d":
                return this.translate.instant("DAY");
            case "1w":
                return this.translate.instant("WEEK");
        }
        return "";
    }

    private getTimePeriodColumnInput(report: Report): string {
        let input = "";
        if (report.period && report.period_param && report.period_param !== "0" && report.period_param !== "1")
            input = report.period_param + " " + report.period;
        if (report.period && report.period_param && report.period_param === "0")
            input = input + this.translate.instant("CURRENT") + " " + this.singularPipe.transform(report.period);
        if (report.period && report.period_param && report.period_param === "1")
            input = input + this.translate.instant("PREVIOUS") + " " + this.singularPipe.transform(report.period);
        if (report.to && report.from)
            input =
                input +
                this.datePipe.transform(report.from, "MMM d, y") +
                " " +
                this.translate.instant("TO").toLowerCase() +
                " " +
                this.datePipe.transform(report.to, "MMM d, y");
        return input;
    }

    selectRow = (report: Report) => {
        this.router.navigate([Constants.urls.reports, report.id]);
    };

    async refresh() {
        this.refreshing = true;

        const reportsRefresh = firstValueFrom(this.reportsService.refreshReports(true));
        const reportRefresh = this.reportID
            ? firstValueFrom(this.reportsService.refreshReport(this.reportID, true))
            : null;
        const reportComponentRefresh =
            this.report && this.report.component ? (this.report.component as ReportComponent).refresh() : null;

        await Promise.all([reportsRefresh, reportRefresh, reportComponentRefresh]);

        this.refreshing = false;
    }

    private async multiAction(action: string, func: (report: Report) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "REPORT", this.selectedRows, func);
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple reports");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (report: Report) => this.reportsService.deleteReport(report));
    }

    async multiEdit() {
        await this.modalService.editMultiple("REPORT", this.selectedRows, async (report: Report, model) => {
            return this.reportsService.updateReport(report, model);
        });
    }

    private startReportsRefresh() {
        this.reportsRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    private stopReportsRefresh() {
        this.reportsRefreshSubscription?.unsubscribe();
    }
}
