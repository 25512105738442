import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { take } from "rxjs/operators";

import * as _ from "lodash";
import moment from "moment";
import { TimezonePipe } from "src/app/pipes/timezone.pipe";

import { Constants, TimeZoneT } from "../../../constants/constants";
import { ReportsService } from "../reports.service";
import { Report } from "../report";
import { SharedService } from "src/app/services/shared.service";
import { TitleService } from "../../../services/title.service";
import { UntypedFormControl, Validators } from "@angular/forms";

@Component({
    selector: "app-report-form",
    templateUrl: "./report-form.component.html",
    providers: [TimezonePipe]
})
export class ReportFormComponent implements OnInit {
    report: Report;
    reportID: string;
    action: string;

    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;
    isAdmin: boolean;

    loading = true;
    saving = false;
    const = Constants;

    granularityOptions = [
        { name: "Hour", value: "1h" },
        { name: "Day", value: "1d" },
        { name: "Week", value: "1w" }
    ];
    uptimeType: string;
    setTimeBy: "period" | "month" | "quarter" | "range";
    selectedMonth: number;
    selectedQuarter: number;
    selectedPeriod: string;
    selectedTimeZone: TimeZoneT;

    timeZones = Constants.timeZones;
    constants: {
        arq_recv_dropped: boolean;
        connections: boolean;
        tr101_Continuity_count_error: boolean;
        tr101_Frozen_video: boolean;
        tr101_Blank_picture: boolean;
        tr101_Silent_audio: boolean;
        tr101_Audio_clipping: boolean;
    };

    monthOptions = _.map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13], offset => {
        return {
            name: moment().subtract(offset, "months").format("MMMM YYYY"),
            value: offset
        };
    });

    quarterOptions = _.map([1, 2, 3, 4, 5], offset => {
        return {
            name: moment().subtract(offset, "Q").format("[Q]Q-Y"),
            value: offset
        };
    });

    periodOptions = [
        { name: "Previous 7 Days", value: "days-7" },
        { name: "Previous 4 Weeks", value: "weeks-4" },
        { name: "Previous 3 Months", value: "months-3" },
        { name: "Current Month", value: "months-0" },
        { name: "Previous Month", value: "months-1" },
        { name: "Current Quarter", value: "quarters-0" },
        { name: "Previous Quarter", value: "quarters-1" },
        { name: "Current Year", value: "years-0" },
        { name: "Previous Year", value: "years-1" }
    ];

    showFromPicker: boolean;
    showToPicker: boolean;
    fromCounter = 0;
    toCounter = 0;
    fromDate: string;
    toDate: string;

    selectedStatuses = [];

    statusList = [
        { name: "Error", id: "show_error" },
        { name: "Warning", id: "show_warning" },
        { name: "OK", id: "show_ok" },
        { name: "Pending", id: "show_pending" },
        { name: "Disabled", id: "show_disabled" }
    ];

    currentSection = "info";

    tagsControl = new UntypedFormControl([], [Validators.required]);
    includeTagsControl = new UntypedFormControl([], []);
    excludeTagsControl = new UntypedFormControl([], []);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.name)
    ]);
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private rs: ReportsService,
        private sharedService: SharedService,
        private timeZonePipe: TimezonePipe,
        private titleService: TitleService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.reportID = params.get("id");
            this.action = params.get("action");
            if (this.reportID) {
                this.report = Object.assign({}, this.rs.getCachedReport(parseInt(this.reportID, 10)));
                // Check if report found in cache
                if (this.sharedService.isEmptyObject(this.report)) {
                    this.rs
                        .refreshReports(true)
                        .pipe(take(1))
                        .subscribe(() => {
                            this.report = Object.assign({}, this.rs.getCachedReport(parseInt(this.reportID, 10)));
                            this.prepForm();
                            this.loading = false;
                        });
                } else {
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }
        });
    }

    prepForm() {
        if (this.action) {
            this.tagsControl.setValue(this.report.resourceTags);
            this.includeTagsControl.setValue(this.report.includeResourceTags);
            this.excludeTagsControl.setValue(this.report.excludeResourceTags);
            if (this.action === "edit") {
                this.nameControl.setValue(this.report.name);
                this.isEdit = true;
            } else if (this.action === "clone") {
                this.isClone = true;
                this.report.name = null;
            }
            this.selectedMonth = 1;
            this.selectedQuarter = 1;
            this.selectedPeriod = "months-0";
            //
            this.selectedStatuses = _.map(
                _.filter(this.statusList, statusOption => {
                    return this.report[statusOption.id];
                }),
                "id"
            );

            this.selectedTimeZone = this.timeZonePipe.transform(this.report.timezone, "utc");

            if (this.report.period && this.report.period_param) {
                this.setTimeBy = "period";
                this.selectedPeriod = this.report.period + "-" + this.report.period_param;
            }

            if (this.report.to && this.report.from) {
                this.setTimeBy = "range";
                this.toDate = moment(this.report.to).format(this.const.DATETIME_SELECTOR_FORMAT);
                this.fromDate = moment(this.report.from).format(this.const.DATETIME_SELECTOR_FORMAT);
            }

            if (this.report.options && this.report.options.thresholds && this.report.options.thresholds.bitrate) {
                this.report.options.thresholds.bitrate = this.report.options.thresholds.bitrate / 1000;
            }
        }

        if (!this.report && !this.isClone && !this.isEdit) {
            this.report = new Report();
            this.report.name = null;
            this.report.type = "usage";
            this.report.options = {
                thresholds: {
                    bitrate: null
                },
                transcoding: false
            };
            this.uptimeType = "sources";
            this.report.breakdown = "1w";
            this.setTimeBy = "period";
            this.selectedMonth = 1;
            this.selectedQuarter = 1;
            this.selectedPeriod = "months-0";

            const date = new Date();
            let offset = new Date().getTimezoneOffset() / -60;
            if (!this.sharedService.isDST(date)) offset = offset + 1;
            this.selectedTimeZone = this.timeZones.find(tz => tz.offset === offset);

            this.report.all_resource_tags = 0;
            this.selectedStatuses = _.map(this.statusList, "id");

            this.constants = {
                arq_recv_dropped: true,
                connections: true,
                tr101_Continuity_count_error: true,
                tr101_Frozen_video: true,
                tr101_Blank_picture: true,
                tr101_Silent_audio: true,
                tr101_Audio_clipping: true
            };
        }

        // Set Title
        this.titleService.setTitle("REPORT", this.action, this.report);
    }

    ngOnInit() {
        this.prepForm();
    }

    statsSummaryCheck() {
        if (
            this.report.type === "stats" &&
            !(
                this.report.feeders ||
                this.report.broadcasters ||
                this.report.receivers ||
                this.report.sources ||
                this.report.channels ||
                this.report.targets
            )
        ) {
            return true;
        } else return false;
    }

    usageReport() {
        if (
            this.report.type === "usage" &&
            !(this.report.sources || this.report.targets || this.report.options.transcoding)
        ) {
            return true;
        } else return false;
    }

    async onSubmit() {
        if (this.statsSummaryCheck()) return;
        if (this.usageReport()) return;
        if (this.checkDate(this.fromDate, this.toDate) && this.setTimeBy === "range") return;

        this.saving = true;

        let fromDate: moment.Moment = null;
        let toDate: moment.Moment = null;
        if (this.setTimeBy === "month") {
            fromDate = moment().subtract(this.selectedMonth, "M").startOf("month");
            toDate = moment().subtract(this.selectedMonth, "M").endOf("month");
        } else if (this.setTimeBy === "quarter") {
            fromDate = moment().subtract(this.selectedQuarter, "Q").startOf("quarter");
            toDate = moment().subtract(this.selectedQuarter, "Q").endOf("quarter");
        } else if (this.setTimeBy === "range") {
            fromDate = moment(this.fromDate, this.const.DATETIME_SELECTOR_FORMAT);
            toDate = moment(this.toDate, this.const.DATETIME_SELECTOR_FORMAT);
        }

        // Convert KBPS
        if (this.report.options && this.report.options.thresholds && this.report.options.thresholds.bitrate) {
            this.report.options.thresholds.bitrate = this.report.options.thresholds.bitrate * 1000;
        }

        if (this.report.type === "usage") {
            this.report.options = _.pick(this.report.options, ["transcoding"]);
        } else {
            this.report.options = {};
        }

        const model = {
            type: this.report.type,
            breakdown: this.report.breakdown,

            options: this.report.options,
            period: this.setTimeBy === "period" ? this.selectedPeriod.split("-")[0] : null,
            period_param: this.setTimeBy === "period" ? this.selectedPeriod.split("-")[1] : null,
            feeders: this.report.feeders || false,
            clusters: this.report.clusters || false,
            broadcasters: this.report.broadcasters || false,
            receivers: this.report.receivers || false,
            sources: this.report.sources || false,
            channels: this.report.channels || false,
            targets: this.report.targets || false,
            to: toDate,
            from: fromDate,
            timezone: this.selectedTimeZone.utc[0],
            all_resource_tags: this.report.all_resource_tags,
            name_filter_include: this.report.name_filter_include || "",
            name_filter_exclude: this.report.name_filter_exclude || "",
            include_resource_tag_ids: _.map(this.includeTagsControl.value, "id"),
            exclude_resource_tag_ids: _.map(this.excludeTagsControl.value, "id"),
            name: this.nameControl.value,

            resource_tag_ids: _.map(this.tagsControl.value, "id")
        };

        Object.assign(
            model,
            this.statusList.reduce((statuses, s) => {
                statuses[s.id] = !!_.find(this.selectedStatuses, f => f === s.id);
                return statuses;
            }, {})
        );

        if (this.isEdit) {
            delete model.type;

            const result = await this.rs.updateReport(this.report, model);
            if (result) {
                this.saving = false;
                this.router.navigate([Constants.urls.reports, this.report.id]);
            } else this.saving = false;
        } else {
            const result = await this.rs.addReport(model);
            if (result) {
                this.saving = false;
                this.router.navigate([Constants.urls.reports]);
            } else this.saving = false;
        }

        this.saving = false;
    }

    cancel() {
        if (this.isEdit || this.isClone) this.router.navigate([Constants.urls.reports, this.report.id]);
        else this.router.navigate([Constants.urls.reports]);
    }

    // Error Code
    infoHasErrors() {
        return this.nameControl.invalid || this.tagsControl.invalid;
    }

    settingsHasErrors(form: NgForm) {
        if (form.form.controls.granularity && form.form.controls.granularity.invalid === true) return true;
        if (form.form.controls.month && form.form.controls.month.invalid === true) return true;
        if (form.form.controls.quarter && form.form.controls.quarter.invalid === true) return true;
        if (form.form.controls.period && form.form.controls.period.invalid === true) return true;
        if (form.form.controls.from && form.form.controls.from.invalid === true) return true;
        if (form.form.controls.to && form.form.controls.to.invalid === true) return true;
        if (this.statsSummaryCheck()) return true;
        if (this.usageReport()) return true;
        if (this.checkDate(this.fromDate, this.toDate) && this.setTimeBy === "range") return true;
        return false;
    }

    filterHasErrors(form: NgForm) {
        if (form.form.controls.bitrate_threshhold && form.form.controls.bitrate_threshhold.invalid === true)
            return true;
        if (form.form.controls.status && form.form.controls.status.invalid === true) return true;
        return false;
    }

    // Scroll Spy Code
    onSectionChange(section: string) {
        this.currentSection = section;
    }

    scrollTo(section: string) {
        document.querySelector("#" + section).scrollIntoView({ behavior: "smooth", block: "start" });
    }

    // check if endDate is after startDate
    checkDate(startDate?, endDate?) {
        if (startDate && endDate) {
            if (new Date(startDate) > new Date(endDate) || startDate === endDate) {
                return true;
            } else return false;
        } else return false;
    }

    // From
    toggleFromPicker() {
        this.showFromPicker = true;
    }

    closeFromPicker() {
        window.focus();
        this.fromCounter = 0;
        this.showFromPicker = false;
    }

    clickOutsideFromPicker() {
        this.fromCounter = this.fromCounter + 1;
        if (this.fromCounter > 1) {
            this.closeFromPicker();
        }
    }

    fromDateChanged(event: Date) {
        if (event !== null) {
            this.fromDate = moment(this.fromDate).format(this.const.DATETIME_SELECTOR_FORMAT);
        }
    }

    // To
    toggleToPicker() {
        this.showToPicker = true;
    }

    closeToPicker() {
        window.focus();
        this.toCounter = 0;
        this.showToPicker = false;
    }

    clickOutsideToPicker() {
        this.toCounter = this.toCounter + 1;
        if (this.toCounter > 1) {
            this.closeToPicker();
        }
    }

    toDateChanged(event: Date) {
        if (event !== null) {
            this.toDate = moment(this.toDate).format(this.const.DATETIME_SELECTOR_FORMAT);
        }
    }

    // Prevent key events except delete and backspace
    onlyDelete(event: KeyboardEvent) {
        if (event.key !== "Backspace" && event.key !== "Delete") event.preventDefault();
    }
}
