import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { NgbNav } from "@ng-bootstrap/ng-bootstrap";
//
import { Constants } from "../../../constants/constants";
import { Tag, UserPermissions } from "../../../models/shared";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";
import { CookieService } from "ngx-cookie-service";
//
import { Report } from "../report";
import { ReportsService } from "../reports.service";
//
import { ReportHistoryComponent } from "./report-history/report-history.component";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";

@Component({
    selector: "app-report",
    templateUrl: "./report.component.html"
})
export class ReportComponent implements OnInit, OnDestroy {
    @ViewChild(ReportHistoryComponent) reportHistory: ReportHistoryComponent;
    @ViewChild("reportTabset") reportTabset: NgbNav;

    report: Report;
    reportID: number;
    resourceTags: Tag[];

    viewOption = "accordions";
    view = "accordions";
    userPermissions: UserPermissions;

    activeTab: string;
    loadingDetails = true;

    private reportsSubscription: Subscription;
    private detailPanelViewSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private rs: ReportsService,
        private modalService: ModalService,
        private sharedService: SharedService,
        private userService: UsersService,
        private cookieService: CookieService,
        private mixpanelService: MixpanelService,
        private titleService: TitleService
    ) {
        this.route.paramMap.subscribe(params => {
            this.reportID = parseInt(params.get("id"), 10);
            if (this.reportID) this.report = this.rs.getCachedReport(this.reportID);
            if (!this.report) return this.cancel();

            // Set Title
            this.titleService.setTitle("REPORT", "", this.report);

            // Loaded Details?
            if (!this.report.hasFullDetails) this.loadingDetails = true;
            this.rs.refreshReport(this.reportID, true);
        });
    }

    refresh() {
        if (this.reportHistory) this.reportHistory.refresh();
    }

    ngOnInit() {
        // Get view from local storage
        if (localStorage.getItem("detail-panel-view")) {
            this.sharedService.setDetailPanelView(localStorage.getItem("detail-panel-view"));
            this.viewOption = localStorage.getItem("detail-panel-view");
        } else {
            this.sharedService.setDetailPanelView("accordions");
            this.viewOption = "accordions";
        }

        this.reportsSubscription = this.rs.reports.subscribe(reports => {
            this.report = reports.find((r: Report) => r.id === this.reportID);
            if (this.report && this.report.hasFullDetails) this.loadingDetails = false;
        });

        this.sharedService
            .getResourceTagsByType("report_templates")
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.resourceTags = tags;
            });

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });

        this.detailPanelViewSubscription = this.sharedService.getDetailPanelView.subscribe(val => {
            this.view = val;
        });
    }

    ngOnDestroy() {
        if (this.reportsSubscription) this.reportsSubscription.unsubscribe();
        this.detailPanelViewSubscription.unsubscribe();
    }

    cancel() {
        this.gotoReports();
    }

    gotoReports() {
        this.router.navigate([Constants.urls.reports]);
    }

    async generateReport() {
        const result = await this.modalService.generateReport(this.report);
        if (result) {
            this.mixpanelService.sendEvent("generate report", {
                id: this.report.id
            });
            this.rs.refreshReport(this.report.id, true);
        } else {
            return false;
        }
    }

    async deleteReport() {
        await this.modalService.confirm(
            "DELETE",
            "REPORT",
            async () => {
                const id = this.report.id;
                const result = await this.rs.deleteReport(this.report);
                if (result) {
                    this.mixpanelService.sendEvent("delete report", { id });
                    this.gotoReports();
                } else {
                    return false;
                }
            },
            this.report.name
        );
    }

    editReport(id: number): void {
        this.router.navigate([Constants.urls.reports, id, "edit"]);
    }

    cloneReport(id: number): void {
        this.router.navigate([Constants.urls.reports, id, "clone"]);
    }

    canEdit(report: Report) {
        return this.sharedService.canEditZixiObject(report, this.resourceTags, this.userPermissions);
    }

    viewChange() {
        this.sharedService.setDetailPanelView(this.viewOption);
        localStorage.setItem("detail-panel-view", this.viewOption);
    }

    public refreshReport = () => {
        this.rs.refreshReport(this.report.id, true);
    };
}
